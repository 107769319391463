import axios from "axios";

export const getAuthToken = () => {
  if (typeof window !== "undefined") {
    // Perform localStorage action
    return localStorage.getItem("authToken");
  } else {
    return null;
  }
};

const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    Authorization: `Bearer ${getAuthToken()}`,
  },
});

export default axiosInstance;
