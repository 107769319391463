/* eslint-disable @next/next/next-script-for-ga */
import Head from "next/head";
import Script from "next/script";

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{title} | AI Prompt marketplace</title>
        <link rel="icon" href="/favicon.png" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
      </Head>
      <Script
        strategy="afterInteractive"
        src="https://www.googletagmanager.com/gtag/js?id=G-8L3D90MH71"
      />
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-8L3D90MH71', {
            page_path: window.location.pathname,
          });
        `,
        }}
      />
    </div>
  );
};

Meta.defaultProps = {
  title: "FlashPrompt | AI Prompt marketplace",
  keyword:
    "Artificial Intelligence, AI Engineer, Smart Coding, Tech Innovation, digital items, Future Technology, AI Development, Next Generation Engineering, Code Enhancement, AI Expert, Machine Learning, Digital Transformation, Automated Coding, AI Services, Cloud Computing, Intelligent Systems.",
  desc: "Find top-notch AI image generation prompts and revolutionize your tech with our prompt engineering marketplace. Discover the latest in AI prompts and use our diffusion model to generate new images. Get creative today!",
};

export default Meta;
