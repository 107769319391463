/* eslint-disable import/no-anonymous-default-export */
import firebase from "firebase/app";
import { useEffect, useState } from "react";
import "firebase/auth";
import { loginUser } from "../services/user";
import { useRouter } from "next/router";

const firebaseConfig = {
  apiKey: "AIzaSyAnUgx89ABzIG6h0W5LWjjPKIza1gA_jeo",
  authDomain: "flash-prompt.firebaseapp.com",
  projectId: "flash-prompt",
  storageBucket: "flash-prompt.appspot.com",
  messagingSenderId: "988502046768",
  appId: "1:988502046768:web:4f8b9915a0e8c5fa0095f5",
  measurementId: "G-8L3D90MH71",
};

// // Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

const formatAuthUser = (user) => ({
  ...user,
  uid: user.uid,
  email: user.email,
});

export const useFirebaseAuth = function useFirebaseAuth() {
  const router = useRouter();
  const app = firebase.app();
  const [authUser, setAuthUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const clear = () => {
    setAuthUser(null);
    setLoading(true);
  };

  const signIn = (provider) => {
    firebase.auth(app).signInWithRedirect(provider);
  };

  const logOut = () => firebase.auth(app).signOut().then(clear);

  const authStateChanged = async (authState) => {
    if (!authState) {
      setAuthUser(null);
      setLoading(false);
      localStorage.removeItem("authToken");
      return;
    } else {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then((idToken) => {
          localStorage.setItem("authToken", idToken);
          return loginUser(idToken);
        })
        .then((response) => {
          var formattedUser = formatAuthUser({
            ...response.data,
            uid: authState.uid,
            email: authState.email,
          });
          setAuthUser(formattedUser);
          setLoading(false);
          if (router.asPath === "/login") {
            router.push("/");
          }
        })
        .catch(() => {
          logOut();
          router.push("/");
        });
    }
  };

  // listen for Firebase state change
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(authStateChanged);
    return () => unsubscribe();
  }, []);

  return {
    authUser,
    loading,
    setAuthUser,
    signIn,
    logOut,
  };
};
