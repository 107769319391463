import axiosInstance, { getAuthToken } from "./axiosInstance";

export function loginUser(idToken) {
  return axiosInstance.post(
    "/login",
    {},
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    }
  );
}

export function getUserApi(id) {
  return axiosInstance.get(`/user/${id}`, {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
}

export function updateUserApi(data, id) {
  return axiosInstance.put(`/user/${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
}
