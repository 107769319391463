import axiosInstance, { getAuthToken } from "./axiosInstance";

export function getAllPromptsApi(page = 1, pageSize = 10) {
  return axiosInstance.get(`/prompts?page=${page}&pageSize=${pageSize}`, {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
}

export function getPromptByIdApi(id) {
  return axiosInstance.get(`/prompt/${id}`);
}

export function createPromptApi(formData) {
  return axiosInstance.post("/prompt", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
}

export function generatePromptApi(prompt, userId) {
  return axiosInstance.post(
    "/prompt/generate",
    { prompt, userId },
    {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    }
  );
}
